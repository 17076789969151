import React, { Fragment } from 'react'
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import FAQ from './component/FAQ';
import Media from './component/Media';

import BTN_CLOSE from '../../assets/images/ic_close.png';

const BaseModal = (props) => {

    const { modal_type, modal_open, modal_closeable } = props.modal;
    const { toggle_modal } = props.actionsModal;
    const pages = {
        faq: FAQ,
        media: Media,
    }

    const renderModal = (type) => {
        const component = pages[type];
        const Handler = component;
        return type ? <Handler {...props}/> : '';
    }

    return (
        <Modal
            open={modal_open}
            onClose={() => toggle_modal()}
            showCloseIcon={false}
            center
            closeOnOverlayClick={true}
            animationDuration={320}
            blockScroll={true}
            classNames={{
                overlay: 'overlay', 
                modal: `modal modal_${modal_type}`,
                closeButton: 'close',
                animationOut: 'fadeOutDown',
                animationIn: 'fadeInUp'
            }}
            >
            <Fragment>
                { modal_closeable && 
                    <div className={`modal__close ${modal_type}`} onClick={() => toggle_modal()}>
                        <img 
                            src={BTN_CLOSE} 
                            alt="Close"
                        />
                    </div>
                } 
                <div className="modal__container">
                    { renderModal(modal_type) }
                </div>
            </Fragment>
        </Modal>
    )
}

export default BaseModal;