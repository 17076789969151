const main_state = {
	login : false,
	loader: false,
	images_preview : '',
	media: '',
	lang_encoded: {
		'id': 0,
		"en": 1,
		"th": 2,
		"cn": 3,
		'ph': 4,
	},
	lang_data: '',
	list_region: [
		'en',
		'id',
		'th',
		'cn',
		'ph',
	],
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data}
		default:
			return state;
	}
};

export default main;