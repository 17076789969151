import React, { Component } from 'react'
import ReactGA from "react-ga"
import { Link } from 'react-router-dom';

class Dropdown extends Component {
    state = {
        showMenu: false,
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e, this.selectWrapper));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event, wrapperRef) => {
        if (wrapperRef && !wrapperRef.contains(event.target)) {
            this.setState({showMenu: false})
        }
    }

    handleMenu = (box, region) => {
        const { lang_data } = this.props.main
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Fanspage Facebook Click'
        });
        if (box === 'facebook')
            switch (region) {
                case 'id':
                    window.open(lang_data.link_fanspage_id, '_blank')
                    break;
                case 'th':
                    window.open(lang_data.link_fanspage_th, '_blank')
                    break;
                case 'cn':
                    window.open(lang_data.link_fanspage_cn, '_blank')
                    break;
                case 'ph':
                    window.open(lang_data.link_fanspage_ph, '_blank')
                    break;
                default:
                    this.setState({showMenu: !this.state.showMenu})
                    break;
            } 
        else this.setState({showMenu: !this.state.showMenu})
    }

    handleClick = (event) => {
        const { lang_encoded } = this.props.main
        const { get_translate } = this.props.actionsMain
        get_translate(lang_encoded[event.target.innerHTML])
    }

    handleRender = (box, children, region) => {
        if (box === 'facebook') {
            return (
                <ul className={`${box}__list ${this.state.showMenu ? 'active' : ''}`}>
                    { children && children.map((child, i) => (
                        <li 
                            key={child[0]} 
                            className={`${box}__list__item`}
                        >
                            <a href={`${child[1]}`} target='_blank' rel='noreferrer'>{child[0]}</a>
                        </li>
                    ))}
                </ul>
            )
        }
        if (box === 'language') { 
            return (
                <ul className={`${box}__list ${this.state.showMenu ? 'active' : ''}`}>
                    { children && children.map((child, i) => (
                        <li 
                            key={child} 
                            className={`${box}__list__item ${region === child ? 'active' : ''}`}
                            onClick={this.handleClick}
                        >
                            <Link to={`/${child}`}>{child}</Link>
                        </li>
                    ))}
                </ul>
            )
        }
    }

    render() {   
        const { box, children, display, region } = this.props
        return (
            <div 
                ref={(wrapper) => { this.selectWrapper = wrapper; }} 
                style={{ position: "relative" }} 
                onClick={() => this.handleMenu(box, region)}>
                    {display}
                    {
                        this.state.showMenu && 
                        <div className={box}>
                            {this.handleRender(box, children, region)}
                        </div>
                    }
            </div>
        );
    }
}

export default Dropdown;