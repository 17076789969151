import React, { Component } from 'react';
import LOGO_GARENA from '../assets/images/logo_garena.png'
import LOGO_TENCENT from '../assets/images/logo_tencent.png'
import LOGO_LIGHTSPEED from '../assets/images/logo_lightspeed.png'

class Footer extends Component {

    render() {
        const { lang_data } = this.props.main
        return (
            <footer className="footer">
                <div className="footer__copyright">
                    <div className="footer__copyright__logo">
                        <img className="footer__copyright__logo__tencent" src={LOGO_TENCENT} alt='Tencent'/>
                        <img className="footer__copyright__logo__lightspeed" src={LOGO_LIGHTSPEED} alt='Lightspeed'/>
                        <img className="footer__copyright__logo__garena" src={LOGO_GARENA} alt='Garena'/>
                    </div>
                    <div className="footer__copyright__text">
                        <p>Copyright &#169; 1998 - 2023 Tencent. All Rights Reserved.</p>
                        <p>Copyright &#169;	Garena Online. Trademarks belong to their respective owners. All Rights Reserved</p>
                    </div>
                </div>
                <div className="footer__legal">
                    <div className="footer__legal__text">
                        <span><a href={lang_data.link_privacy_policy} target="_blank" rel="noreferrer">{lang_data.text_privacy_policy}</a></span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;