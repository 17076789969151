import React, { Component } from 'react';
import ReactGA from "react-ga"
import Dropdown from 'helper/component/dropdown';

import LOGO_GAME from '../assets/images/logo_game.png'
import BTN_FB from '../assets/images/btn_fb.png'
import BTN_FB_MIN from '../assets/images/btn_fb_min.png'

class Navbar extends Component {

    state = {
        scrolled: false
    }

    navbarClasses = ['navbar']


    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll)
        
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate() {
        this.navbarClasses = ['navbar']
        if (this.state.scrolled) this.navbarClasses.push('scrolled')
    }

    handleFanspage = (lang_data) => {
        return [
            ['Hongkong', lang_data.link_fanspage_cn],
            ['Indonesia', lang_data.link_fanspage_id],
            ['Macau', lang_data.link_fanspage_cn],
            ['Malaysia', lang_data.link_fanspage_sg_my],
            ['Singapore', lang_data.link_fanspage_sg_my],
            ['Phillippines', lang_data.link_fanspage_ph],
            ['Taiwan',	lang_data.link_fanspage_cn],
            ['Thailand', lang_data.link_fanspage_th],
        ]
    }

    handleScroll = () => {
        const offset = window.scrollY
        if (offset > 200) {
            ReactGA.event({
                category: 'Promotion',
                action: 'Scroll',
                label: 'Preview Scrolled'
            });
            this.setState({scrolled: true})
        } else {
            this.setState({scrolled: false})
        }
    }

    handleToTop = () => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
    }

    render() {
        const { lang_data } = this.props.main
        const { region } = this.props
        return (
            <nav className={this.navbarClasses.join(' ')}>
                <div className="navbar__wrapper">
                    <div 
                        className="navbar__logo"
                        onClick={() => this.handleToTop()}
                        >
                        <img src={LOGO_GAME} alt='Dawn Awakening'/>
                    </div>
                    <div className="navbar__menu">
                        {/* <div className="navbar__menu__language">
                            <Dropdown
                                {...this.props}
                                onClick={() => this.props.onClick && this.props.onClick()}
                                box="language"
                                display={(
                                    <button className="language__button">
                                        <img src={BTN_GLOBE} alt="Language"/>
                                    </button> 
                                    )}
                                >
                                {list_language}
                            </Dropdown>
                        </div> */}
                        <div className="navbar__menu__facebook">
                            <Dropdown
                                {...this.props}
                                box="facebook"
                                display={(
                                    <button className="facebook__button">
                                        {region === 'id' || region === 'th' || region === 'cn' || region === 'ph' ? 
                                            <img src={BTN_FB_MIN} alt="Facebook"/>
                                            :
                                            <img src={BTN_FB} alt="Facebook"/>
                                        }
                                    </button> 
                                    )}
                                >
                                {lang_data && this.handleFanspage(lang_data)}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;