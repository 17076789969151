import React, { Component } from 'react';
import ReactGA from "react-ga"

import Navbar from 'partial/Navbar';
import Footer from 'partial/Footer';
import Carousel from 'helper/component/carousel';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as authActions from 'redux/actions/auth';
import * as modalActions from 'redux/actions/modal';

import BORDER_VIDEO from '../assets/images/border_video.png'
import BTN_PLAY from '../assets/images/ic_play.png'
import LINE_TITLE from '../assets/images/line_title.png'

import TITLE_PREVIEW from '../assets/images/title_preview.png'
import TITLE_PREVIEW_CN from '../assets/images/title_preview_cn.png'

import LOGO_TITLE from '../assets/images/logo_game_title.png'
import LOGO_TITLE_CN from '../assets/images/logo_game_title_cn.png'
import LOGO_TITLE_TH from '../assets/images/logo_game_title_th.png'

class Home extends Component {

    title_preview = {
        'id': TITLE_PREVIEW,
        'en': TITLE_PREVIEW,
        'ph': TITLE_PREVIEW,
        'th': TITLE_PREVIEW,
        'cn': TITLE_PREVIEW_CN,
    }
    
    logo_title = {
        'id': LOGO_TITLE,
        'en': LOGO_TITLE,
        'ph': LOGO_TITLE,
        'cn': LOGO_TITLE_CN,
        'th': LOGO_TITLE_TH,
    }
    
    state = {
        animation: 'fadeIn'
    }

    componentDidMount() {
        const { params } = this.props.match
        const { lang_encoded } = this.props.main 
        const region = this.handleDomain() || (params.region ? params.region : 'en')
        this.props.actionsMain.get_translate(lang_encoded[region])
    }

    handleModal = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Learn More Click'
        });
        this.props.actionsModal.toggle_modal('faq', true, true);
    }

    handleDomain = () => {
        const domain = window.location.hostname
        switch (domain) {
            case 'ud.garena.com':
            case 'ud.garena.ph':
                return 'en'
            case 'ud.garena.co.id':
                return 'id'
            case 'ud.garena.tw':
                return 'cn'
            case 'ud.garena.in.th':
                return 'th'
            default:
                return ''
        }
    }

    render() {
        const { params } = this.props.match
        const { lang_data, images_preview } = this.props.main
        const region = this.handleDomain() || (params.region ? params.region : 'en')
        return (
            <div className="home">
                <div className="home__section join">
                    <Navbar 
                        {...this.props}
                        region={region}
                    />
                    <div className={`join__content ${this.state.animation}`}>
                        <img src={this.logo_title[region]} alt="DAWN AWAKENING"/>
                        <button
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Promotion',
                                    action: 'Click',
                                    label: 'Register Access Click'
                                });
                                window.fbq != null && window.fbq('trackCustom', 'RegisterAccess');
                            }}
                        >
                            <a 
                                href={lang_data.link_early_access ? lang_data.link_early_access : ''} 
                                target='_blank' 
                                rel='noreferrer'>
                                    {lang_data.btn_early_access}
                            </a>
                        </button>
                        <p>{lang_data.text_faq}<button onClick={this.handleModal}>{lang_data.text_learn_more}</button></p>
                    </div>
                </div>
                <div className="home__section preview">
                    <div className="preview__content">
                        <div className="preview__content__title">
                            <img src={this.title_preview[region]} alt="Preview"/>
                            <img className="line" src={LINE_TITLE} alt=""/>
                        </div>
                        <div className="preview__content__galery">
                            <div className="video">
                                <div 
                                    className="video__border"
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'Promotion',
                                            action: 'Click',
                                            label: 'Video Click'
                                        });
                                        this.props.actionsMain.put_data('media', 'video')
                                        this.props.actionsModal.toggle_modal('media', true, true)
                                    }}
                                    >
                                    <img src={BORDER_VIDEO} alt="" />
                                    <img
                                        className="video__content" 
                                        src={lang_data.img_thumbnail_video} alt=""/>
                                    <img 
                                        className='video__play'
                                        src={BTN_PLAY} alt='Reward'/> 
                                </div>
                            </div>
                            <div className="image">
                                <Carousel {...this.props}>
                                    { images_preview }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                    <Footer {...this.props}/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {
		main: state.main,
		modal: state.modal,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
		actionsAuth: bindActionCreators(authActions, dispatch),
		actionsModal: bindActionCreators(modalActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
