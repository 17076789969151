import React, { Component } from 'react';
import ReactGA from "react-ga"

import IC_ARROW from '../../../assets/images/ic_arrow_two.png'

class ModalPicture extends Component {

    state = {
        current: this.props.main.position,
        page: this.props.main.images_preview,
        touchStart: 0,
        touchEnd: 0,
        animation: ''
    }

    handleTouchStart = (e) => {
        this.setState({touchStart: e.targetTouches[0].clientX})
    }
    
    handleTouchMove = (e) => {
        this.setState({touchEnd: e.targetTouches[0].clientX})
    }
    
    handleTouchEnd = () => {
        if (this.state.touchStart - this.state.touchEnd > 150) {
            // do your stuff here for left swipe
            this.handleNext();
        }
        if (this.state.touchStart - this.state.touchEnd < -150) {
            // do your stuff here for right swipe
            this.handlePrev();
        }
    }

    handlePrev = () => {
        this.setState({ animation: 'fadeInLeft'})
        if (this.state.current - 1 >= 0)
            this.setState(prevState => ({ current: prevState.current - 1 }))
        else
            this.setState({ current: this.state.page.length - 1 });
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Slider Click'
        });
    }

    handleNext = () => {
        this.setState({ animation: 'fadeInRight'})
        if (this.state.current + 1 < this.state.page.length)
            this.setState(prevState => ({ current: prevState.current + 1 }))
        else
            this.setState({ current: 0 })
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Slider Click'
        });
    }

    handleContent = (tipe) => {
        if (tipe === 'video') {
            const {lang_data} = this.props.main;
            const video = lang_data.link_video ? 
                lang_data.link_video.replace('watch?v=', 'embed/') : 'https://www.youtube.com/embed/c4cwcihb-KE';
            return (
                <div className="media">
                    <iframe 
                        title="Undawn Awakening Video"
                        src={`${video}?autoplay=1&modestbranding=1&rel=0`}
                        frameborder="0" 
                        allowfullscreen/>
                </div>
            )
        }
        if (tipe === 'image') {
            return (
                <div className="media">
                    <button
                        className="media__prev" onClick={() => this.handlePrev()}>
                        <img src={IC_ARROW} alt="Previous" />
                    </button>
                    <div 
                        className="media__container"
                        onTouchStart={this.handleTouchStart}
                        onTouchMove={this.handleTouchMove}
                        onTouchEnd={this.handleTouchEnd}
                    >
                        { this.props.main.images_preview.length > 0 && 
                            this.props.main.images_preview.map((image, i) => (
                                <div 
                                    key={i} 
                                    className={`media__item 
                                        ${this.state.current === i ? this.state.animation : 'hidden'}`}
                                    >
                                    <img src={image[1]} alt="" />
                                </div>
                            )
                        )}
                    </div>
                    <button
                        className="media__next" onClick={() => this.handleNext()}>
                        <img src={IC_ARROW} alt="Next" />
                    </button>
                </div>
            )
        }
    }

    render() {
        const { media } = this.props.main
        return (
            this.handleContent(media)
        );
    }
}

export default ModalPicture;