import ReactGA from "react-ga"
import axios from "axios"
import { getUrlParam } from "helper"

// import { toggle_modal } from "./modal"

const ls = window.localStorage

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
	transformRequest: [
		(data, headers) => {
			delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
			return data
		},
	],
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

export const put_data_hash = (key, data) => ({
	type: "PUT_DATA_HASH",
	key,
	data,
})

/**
 *
 * Example of how to call API
 *
 */
export const login = (hashKey, hashValue) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get(process.env.REACT_APP_API_ENDPOINT)
			.then((resp) => {
				dispatch(put_data("login", resp.data))

				// If you want to set data as HASH
				// dispatch(put_data_hash("login", resp.data))
				// dispatch(setHash(hashKey, hashValue))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.msg))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

/**
 *
 * Example of how to implement multi language
 *
 */
 export const get_translate = (lang_encoded) => {
    return (dispatch) => {
        dispatch(toggle_loader(true));
        cdn.get(`data.json?${Math.random() * 10000}`)
            .then((resp) => {
				const data = resp.data.data
				const someEmpty = Object.values(data[lang_encoded])
					.some(x => (x === null || x === ''));

				if (someEmpty) {
					ReactGA.event({
						category: 'Report',
						action: 'Load',
						label: 'Load Data Failed'
					});
				}
				dispatch(get_image(data, lang_encoded));
                dispatch(put_data('lang_data', data[lang_encoded]));
                dispatch(toggle_loader(false));
            })
            .catch((err) => {
                dispatch(catch_error(err.response?.data?.msg));
            });
    };
};

export const get_image = (data, lang_encoded) => {
	return (dispatch) => {
		const images = Object.fromEntries(
			Object
			.entries(data[lang_encoded])
			.filter(([key, value]) => key.includes('img_preview'))
		)
		const imageArr = Object.entries(images)
		dispatch(put_data('images_preview', imageArr));
	}
}


/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
	return (dispatch) => {
		if (err_msg === "not_logged_in") {
			const param = {
				accessToken: getUrlParam("access_token") || "",
				accountId: getUrlParam("account_id") || "",
				region: getUrlParam("region") || "",
			}

			ls.removeItem("cache")
			ls.removeItem("hash")

			window.location.href = `${`${process.env.REACT_APP_BASE_URL}?access_token=${param.accessToken}&account_id=${param.accountId}&region=${param.region}`}`
		} else {
			if (err_msg) {
				dispatch(put_data("err_msg", err_msg))
			}
			// TODO 
			//dispatch(toggle_modal(true, "error"))
			dispatch(toggle_loader(false))
		}
	}
}
