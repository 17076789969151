import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from 'helper/component/viewport';
import BaseModal from 'partial/modal';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';

// Pages
import Home from 'pages/Home';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as authActions from 'redux/actions/auth';
import * as modalActions from 'redux/actions/modal';

class Main extends Component {

    render() {
		const { loader } = this.props.main
        return (
            <Router basename={`${process.env.PUBLIC_URL}`}>
				<div id="Main" className="main-panel">
					<ErrorBoundary>
						<ViewportRestrict display={false} type="landscape"/>
						<Loader loader={loader} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={Home} />
								<Route exact path="/:region" component={Home} />
								<Route component={NotFound} />
							</Switch>
						</div>
						<BaseModal {...this.props}/>
					</ErrorBoundary>
				</div>
			</Router>
        );
    }
}

function mapStateToProps(state) {
	return {
		main: state.main,
		modal: state.modal,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
		actionsAuth: bindActionCreators(authActions, dispatch),
		actionsModal: bindActionCreators(modalActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);