import React, { Component } from 'react'
import ReactGA from "react-ga"

import LINE_PAGE from '../../../assets/images/line_page.png'
import IC_ARROW from '../../../assets/images/ic_arrow_one.png'

class Carousel extends Component {
    state = {
        current: 0,
        page: [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11]
        ],
        touchStart: 0,
        touchEnd: 0,
        animation: '',
    }

    handleTouchStart = (e) => {
        this.setState({touchStart: e.targetTouches[0].clientX})
    }
    
    handleTouchMove = (e) => {
        this.setState({touchEnd: e.targetTouches[0].clientX})
    }
    
    handleTouchEnd = () => {
        if (this.state.touchStart - this.state.touchEnd > 150) {
            // do your stuff here for left swipe
            this.handleNext();
        }
        if (this.state.touchStart - this.state.touchEnd < -150) {
            // do your stuff here for right swipe
            this.handlePrev();
        }
    }

    handlePrev = () => {
        this.setState({ animation: 'fadeInLeft'})
        if (this.state.current - 1 >= 0)
            this.setState(prevState => ({ current: prevState.current - 1 }))
        else
            this.setState({ current: this.state.page.length - 1 });
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Slider Click'
        });
    }

    handleNext = () => {
        this.setState({ animation: 'fadeInRight'})
        if (this.state.current + 1 < this.state.page.length)
            this.setState(prevState => ({ current: prevState.current + 1 }))
        else
            this.setState({ current: 0 })
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Slider Click'
        });
    }

    handleClick = (position) => {
        ReactGA.event({
            category: 'Promotion',
            action: 'Click',
            label: 'Image Click'
        });
        this.props.actionsMain.put_data('position', position)
        this.props.actionsMain.put_data('media', 'image')
        this.props.actionsModal.toggle_modal('media', true, true)
    }

    render = () => {
        const { children } = this.props
        return (
            <div>
                <div className="carousel">
                    <button
                        className="carousel__prev" onClick={() => this.handlePrev()}>
                        <img src={IC_ARROW} alt="Previous" />
                    </button>
                    <div 
                        className="carousel__container"
                        onTouchStart={this.handleTouchStart}
                        onTouchMove={this.handleTouchMove}
                        onTouchEnd={this.handleTouchEnd}
                    >
                        { children
                            && children.length > 0 && children.map((image, i) => (
                                <div 
                                    key={i} 
                                    className={`carousel__item 
                                        ${this.state.page[this.state.current].includes(i) ? this.state.animation : "hidden"}`}
                                    onClick={() => this.handleClick(i)}
                                    >
                                    <img src={image[1]} alt="" />
                                </div>
                            ))
                        }
                    </div>
                    <button
                        className="carousel__next" onClick={() => this.handleNext()}>
                        <img src={IC_ARROW} alt="Next" />
                    </button>
                </div>
                <div className="carousel__indicator">
                    {this.state.page.map((page, i) => (
                        <img 
                            key={i} 
                            className={`${i} ${this.state.current !== i ? "disabled" : ""}`} 
                            src={LINE_PAGE} alt="" 
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default Carousel;