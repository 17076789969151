import { combineReducers } from "redux"
import main from "./main"
import hash from "./hash"
import modal from "./modal"

export default combineReducers({
	main,
	hash,
	modal,
})
