import React, { Component } from 'react';
import TITLE_FAQ from '../../../assets/images/title_faq.png'
import LINE_TITLE from '../../../assets/images/line_title.png'

class ModalFAQ extends Component {

    render() {
        const { lang_data } = this.props.main;
        return (
            <div className="faq">
                <div className="faq__content">
                    <div className="faq__content__title">
                        <img src={TITLE_FAQ} alt="Preview"/>
                        <img className="line" src={LINE_TITLE} alt=""/>
                    </div>
                    <div 
                        className="faq__content__body"
                        dangerouslySetInnerHTML={{
                            __html: lang_data.content_faq,
                        }}>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalFAQ;