import "react-app-polyfill/ie9"
import React from "react"
import ReactDOM from "react-dom"
import ReactGA from "react-ga"
import axios from "axios"
// import * as serviceWorker from './serviceWorker'; // UNCOMMENT FOR PWA

// COMPONENT & OTHER
import "assets/scss/index.scss"
import Main from "base/Main"

// REDUX
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducer from "redux/reducers"

// REDUX PERSIST
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { PersistGate } from "redux-persist/integration/react"

// ReactGA.initialize([
// 	{
// 		trackingId: process.env.REACT_APP_GA,
// 		gaOptions: {
// 			name: "website",
// 		},
// 	},
// 	{
// 		trackingId: process.env.REACT_APP_GA_ERROR_REPORT,
// 		gaOptions: {
// 			name: "error_report",
// 		},
// 	},
// ])
ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.pageview(window.location.pathname + window.location.search, ["website"])

// SET REDUX STORE
const persistedReducer = persistReducer(
	{ key: "root", storage, whitelist: ["hash"] },
	rootReducer
)
const store = createStore(persistedReducer, applyMiddleware(thunk))
const persistor = persistStore(store)

// SET DEFAULT AXIOS
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

if (process.env.REACT_APP_USE_TOKEN === "true") {
	var token = window.localStorage.getItem("token")
	axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token
}

// SET AXIOS INTERCEPTOR
axios.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error

		const config = {
			method: error?.response?.config?.method,
			status_code: error?.response?.status,
			endpoint: error?.response?.request?.responseURL,
		}

		const { method, status_code, endpoint } = config

		// Run only on production
		if (process.env.NODE_ENV === "production") {
			// Check status code, if below 5xx, don't send. Send report only 5xx error
			if (status_code > 499) {
				console.log("SEND ERROR REPORTING")
				// Will send data like 'www.FE.com -> https://www.BE.com/api/error/
				ReactGA.event(
					{
						category: `${window.location.hostname} -> ${endpoint}`,
						action: method,
						label: status_code.toString(),
						nonInteraction: true,
					},
					["error_report"]
				)
			}
		}

		return Promise.reject(error)
	}
)

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<React.StrictMode>
				<Main />
			</React.StrictMode>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
)

// serviceWorker.unregister(); // UNCOMMENT TO REMOVE PWA || COMMENT THE REGISTER
// serviceWorker.register(); // UNCOMMENT FOR PWA
