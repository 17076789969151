const modal_state = {
    modal_type: '',  
    modal_open: false,
    modal_closeable: true,
};
  
const modal = (state = modal_state, action) => {
    switch (action.type) {
    case 'TOGGLE_MODAL':
        return {
            ...state,
            modal_type: action.modal,
            modal_open: action.toggle,
            modal_closeable: action.closeable,
        };
    default:
        return state;
    }
};
  
export default modal;